import ApplicationController from "./application_controller";
// import Rails from "@rails/ujs"

const REGION_MODE = 'region'
const PROVINCE_MODE = 'province'
const COLORS = {
  contacts: ['#1e3a8a', '#1e40af', '#1d4ed8', '#2563eb', '#3b82f6', '#60a5fa', '#93c5fd'],
  subscriptions: ['#7c2d12', '#9a3412', '#c2410c', '#ea580c', '#f97316', '#fb923c', '#fdba74']
}
const BAR_COLOR_INDEX = 4

export default class extends ApplicationController {
  static targets = ['activeFilter', 'table', 'geo', 'contacts', 'subscriptions', 'bars']
  static values = {
    url: String,
    activeFilter: String,
    from: String,
    to: String
  }

  fullData = null
  mapView = null
  tableView = null
  geo = null
  table = null
  bars = null
  contactsPie = null
  subscriptionsPie = null

  async connect() {
    try {
      await google.charts.load('current', {
        'packages': ['corechart', 'table', 'geochart'],
        'mapsApiKey': 'AIzaSyDFUfEyJ1eZpOTBt9a0adGAvUeM2qSupl0',
        'language': 'it'
      })

      this.loadData()
    } catch(error) {
      console.log('error')
    }
  }

  activeFilterValueChanged() {
    this.activeFilterTarget.innerHTML = this.activeFilterValue || 'Italia'
  }

  loadData() {
    Rails.ajax({
      url: this.urlValue,
      data:  new URLSearchParams({ from: this.fromValue, to: this.toValue }).toString(),
      type: "get",
      success: (data) => this.start(data)
    })
  }

  start(data) {
    this.fullData = new google.visualization.DataTable(data)

    this.mapView = google.visualization.data.group(this.fullData, [0, 1], [
      { column: 3, aggregation: google.visualization.data.sum, type: 'number' },
      { column: 4, aggregation: google.visualization.data.sum, type: 'number' }
    ])

    this.tableView = new google.visualization.DataView(this.mapView)

    this.setupCharts()
    this.bindEvents()
    this.draw()
  }

  setupCharts() {
    this.geo = new google.visualization.GeoChart(this.geoTarget)
    this.table = new google.visualization.Table(this.tableTarget)
    this.bars = new google.visualization.BarChart(this.barsTarget)
    this.contactsPie = new google.visualization.PieChart(this.contactsTarget)
    this.subscriptionsPie= new google.visualization.PieChart(this.subscriptionsTarget)
  }

  bindEvents() {
    this.geoSelectBind()
  }

  geoSelectBind() {
    google.visualization.events.addListener(this.geo, 'select', ()=> {
      const selectedItem = this.geo.getSelection()[0];

      if (selectedItem) {
        let value = this.mapView.getValue(selectedItem.row, 0)

        this.tableView = new google.visualization.DataView(this.fullData)
        this.tableView.setRows(this.tableView.getFilteredRows([{ column: 'code', value: value }]))

        this.modeValue = PROVINCE_MODE
        this.activeFilterValue = this.mapView.getValue(selectedItem.row, 1)
      } else {
        this.tableView = this.mapView

        this.modeValue = REGION_MODE
        this.activeFilterValue = ''
      }

      this.drawCharts()
    })
  }

  draw() {
    this.drawGeo()
    this.drawCharts()
  }

  drawCharts() {
    this.drawTable()
    this.drawPieContacts()
    this.drawPieSubscription()
    this.drawBars()
  }

  drawGeo() {
    let options = {
      region: "IT",
      displayMode: "regions",
      resolution: "provinces",
      legend: false,
      colorAxis: { colors: COLORS.contacts.reverse() },
    };

    this.geo.draw(this.mapView, options)
  }

  drawTable() {
    let options = {
      width: '100%',
      alternatingRowStyle: false,
      cssClassNames: { headerRow: 'cursor-pointer',  tableRow: 'nc',  oddTableRow: 'nc',  selectedTableRow: 'nc',  hoverTableRow: 'nc',  headerCell: 'nc',  tableCell: 'nc', rowNumberCell: 'nc'},
      sortColumn: 2,
      sortAscending: false
    }

    if ( this.modeValue == PROVINCE_MODE ) {
      this.tableView.hideColumns([1])
    }

    // console.log(this.tableView.getViewRows(), this.tableView.getSortedRows({ column: 2, desc: true }))
    // this.tableView.setRows(this.tableView.getSortedRows({ column: 2, desc: true }))
    this.table.draw(this.tableView, options);

    this.tableTarget.querySelector('.google-visualization-table').classList.toggle('google-visualization-table')
    let s = this.tableTarget.querySelector('.google-visualization-table-table').classList
    s.remove('google-visualization-table-table')
    s.add('table', 'table-striped', 'table-hover', 'table-sm')
  }

  drawPieContacts() {
    this.drawPie('contacts', [1, 2])
  }

  drawPieSubscription() {
    this.drawPie('subscriptions', [1, 3])
  }

  drawPie(kind = 'contacts', columns) {
    let chart = kind + 'Pie'
    let options = {
      colors: COLORS[kind],
      legend: 'none',
      chartArea: { width: '95%', height: '95%' },
      width: 250,
    };

    let pieView = new google.visualization.DataView(this.tableView)
    pieView.setColumns(columns)

    this[chart].draw(pieView, options);
  }

  drawBars() {
    let options = {
      colors: [COLORS.contacts[BAR_COLOR_INDEX], COLORS.subscriptions[BAR_COLOR_INDEX]],
      height: 500,
      chart: {
        title: 'Contatti e Iscrizioni'
      },
      hAxis: {
        minValue: 0,
      },
      legend: { position: 'top', alignment: 'center' },
      chartArea: { width: '78%', height: '90%', right: 20 },
      responsive: true,
    };

    let barsView = new google.visualization.DataView(this.tableView)
    barsView.setColumns([1, 2, 3])

    this.bars.draw(barsView, options)
  }
}
